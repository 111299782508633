<template>
    <b-modal :id="windowId" title="Update Customer">
        <b-container fluid>
            <div class="form-group">
                <amdin-input v-model="email" name="email" title="Email" :errors="serverErrors" />
                <amdin-input v-model="phone" name="phone" title="Phone" :errors="serverErrors" />
                <amdin-input v-model="firstName" name="first_name" title="First Name" :errors="serverErrors" />
                <amdin-input v-model="lastName" name="last_name" title="Last Name" :errors="serverErrors" />
                <amdin-input v-model="country" name="country" title="Country" :errors="serverErrors" />
                <amdin-input v-model="city" name="city" title="City" :errors="serverErrors" />
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Submit</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import amdinInput from './../../Form/dnd-admin-input';
import { randString } from './../../../mixins/rand-string.mixin';

export default {
    mixins: [randString],
    components: {amdinInput},
    data() {
        return {
            windowId: this.getRandStr(8),
            id: null,
            email: null,
            phone: null,
            firstName: null,
            lastName: null,
            city: null,
            country: null,
    
            serverErrors: []
        }
    },
    methods: {
        show(customer_id) {
            this.id = customer_id;

            this.$http({ url: 'customer/' + customer_id, method: 'GET'})
                .then(res => {
                    this.email = res.data.customer.email;
                    this.phone = res.data.customer.phone;
                    this.firstName = res.data.customer.first_name;
                    this.lastName = res.data.customer.last_name;
                    this.city = res.data.customer.city;
                    this.country = res.data.customer.country;

                    this.$bvModal.show(this.windowId);
                });
        },
        save() {
            this.$http.put('customer/'+ this.id  +'/update', {
                'email': this.email,
                'phone': this.phone,
                'first_name': this.firstName,
                'last_name': this.lastName,
                'country': this.country,
                'city': this.city
            }).then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide(this.windowId);
            this.$emit('saved', true);
        },
        responseError(res) {
            if(res.response.data.hasOwnProperty('errors')) {
                this.serverErrors = res.response.data.errors;
            }
            this.$emit('saved', false);
        }
    }
}
</script>
