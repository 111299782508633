<template>
    <div>
        <nav-admin />
        <div class="container">
            <div v-if="customer" class="row mt-3 mb-4">
                <div class="col-2">
                    <router-link :to="{ name: 'Customers' }">Back to Customers</router-link>
                </div>
                <div v-if="customer" class="col-10">
                    <button class="btn btn-primary float-right" @click="$refs.updateCustomerPopup.show(customer.id)">Edit</button>
                </div>
            </div>
            <div v-if="customer" class="row mt-2 mb-2">
                <div class="col-12">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td>Email:</td>
                                <td>{{ customer.email }}</td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td>{{ customer.phone }}</td>
                            </tr>
                            <tr>
                                <td>Full name:</td>
                                <td> {{ customer.first_name }} {{ customer.last_name }}</td>
                            </tr>
                            <tr>
                                <td>Country:</td>
                                <td> {{ customer.country }}</td>
                            </tr>
                            <tr>
                                <td>City:</td>
                                <td> {{ customer.city }}</td>
                            </tr>
                            <tr v-if="customer.orders_total">
                                <td>Orders count:</td>
                                <td> {{ customer.orders_total }}</td>
                            </tr>
                            <tr v-if="customer.spent_money_total">
                                <td>Paid amount:</td>
                                <td> ${{ customer.spent_money_total }}</td>
                            </tr>
                            <tr v-if="customer.last_order_date">
                                <td>Last order date:</td>
                                <td> {{ convertBrouserTZDateOnly(customer.last_order_date) }}</td>
                            </tr>
                            <tr v-if="customer.first_order_date">
                                <td>First order date:</td>
                                <td> {{ convertBrouserTZDateOnly(customer.first_order_date) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="tableData.length">
                <h4>Customer Activities</h4>
                <b-table
                    id="orders"
                    :items="tableData"
                    :fields="tableFields"
                    :per-page="tableData.length"
                    :current-page="1">
                    <template v-slot:cell(created_at)="data">
                        {{ convertBrouserTZDateOnly(data.item.created_at) }}
                    </template>
                    <template v-slot:cell(type)="data">
                        {{ data.item.type }}
                    </template>
                    <template v-slot:cell(controls)="data">
                        <button v-if="data.item.type == 'Order'" @click="$refs.orderNotifiactionPopup.show(data.item.id)" class="btn btn-primary float-right">Details</button>
                        <button v-else class="btn btn-primary float-right" @click="$refs.fullTextPopup.show(data.item.text)">Details</button>
                    </template>
                </b-table>
                
            </div>
            <div v-if="events.length">
                <h4>Marketing Campaigns</h4>
                <b-table
                    id="events"
                    :items="events"
                    :fields="[
                        { key: 'subject', label: '' },
                        { key: 'date', label: 'Date' },
                        { key: 'isSent', label: 'Status' },
                        { key: 'controls', label: '' }
                    ]"
                    :per-page="events.length"
                    :current-page="1">
                    <template v-slot:cell(subject)="data">
                        {{ data.item.subject }}
                    </template>
                    <template v-slot:cell(date)="data">
                          {{ data.item.isSent ? convertBrouserTZDateOnly(data.item.date) : '' }}
                    </template>
                    <template v-slot:cell(isSent)="data">
                          {{ data.item.isSent ? 'Sent' : '' }}
                    </template>
                    <template v-slot:cell(controls)="data">
                          <button @click="openEvent(data.item.event_id)" class="btn btn-primary float-right">Detail</button>
                    </template>
                </b-table>
            </div>
        </div>
        <full-text-popup ref="fullTextPopup" />
        <order-notification-popup ref="orderNotifiactionPopup" />
        <update-customer-popup @saved="onSaved" ref="updateCustomerPopup" />
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import navAdmin from './../../components/nav-admin';
import { orderProcessStages } from './../../mixins/order-process-stages.mixin';
import { timeZone } from './../../mixins/time-zone.mixin';
import fullTextPopup from './../ContactForms/FormTextPopup';
import orderNotificationPopup from './../Orders/Components/OrderNotificationMessagePopup';
import updateCustomerPopup from './Components/edit-customer-popup';
export default {
    mixins: [orderProcessStages, timeZone],
    components: { navAdmin, fullTextPopup, orderNotificationPopup, updateCustomerPopup },
    data() {
        return {
            customer: null,
            isEdit: false,
            tableData: [],
            events:[],
            tableFields: [
                { key: 'created_at', label: "Date" },
                { key: 'type', label: "Type" },
                { key: 'controls', label: "" }
            ]
        }
    },
    methods: {
        ... mapActions(['getEventAction']),
        onSaved(isSaved) {
            if(isSaved) {
                this.load();
            }
        },
        async openEvent(eventId) {
            await this.getEventAction(eventId);
            this.$router.push({ 
                name: 'EventEditor', 
                params: { 'event_id': eventId }
            });
        },
        load() {
            this.$http({ url: 'customer/' + this.$route.params.id, method: 'GET'})
                .then(res => {
                    this.customer = res.data.customer;
                    
                    this.tableData = [];
                    
                    for(let i = 0; i < res.data.customer.orders.length; i++) {
                        const order = res.data.customer.orders[i];
                        this.tableData.push({
                            id: order.id,
                            created_at: order.created_at,
                            type: 'Order'
                        })
                    }

                    for(let i = 0; i < res.data.customer.contact_forms.length; i++) {
                        const mail = res.data.customer.contact_forms[i];
                        this.tableData.push({
                            id: mail.id,
                            created_at: mail.created_at,
                            type: 'Contact form',
                            text: mail.text
                        })
                    }

                    this.tableData.sort((a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0))

                    for(let i = 0; i < res.data.customer.events.length; i++) {
                        const item = res.data.customer.events[i];
                        if(item.is_sent) {
                            this.events.push({
                                isSent: item.is_sent,
                                subject: item.subject,
                                date: item.event_date,
                                event_id: item.marketing_event_id
                            });
                        }
                    }
                });
        }
    },
    mounted() {
        this.customer = null;
        this.load();        
    }
}
</script>
<style scoped>

</style>