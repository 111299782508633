<template>
    <b-modal :id="windowId" title="Contact Form Text">
        <b-container fluid>
            <pre v-html="text"></pre>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { randString } from './../../mixins/rand-string.mixin';

export default {
    mixins: [ randString ],
    data() {
        return {
            text: null,
            windowId: this.getRandStr(8)
        }
    },
    methods: {
        show(text) {
            this.text = text;
            this.$bvModal.show(this.windowId);
        }
    }
}
</script>
