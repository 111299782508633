<template>
    <b-modal :id="windowId" title="Order">
        <b-container fluid>
            <pre v-html="text"></pre>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { randString } from './../../../mixins/rand-string.mixin';

export default {
    mixins: [ randString ],
    data() {
        return {
            text: null,
            windowId: this.getRandStr(8)
        }
    },
    methods: {
        show(order_id) {
            this.$http({ url: 'order/'+ order_id +'/notification-message', method: 'GET'}).then(res => {
                this.text = res.data.text;
                this.$bvModal.show(this.windowId);
            });
        }
    }
}
</script>
